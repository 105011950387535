<template>
  <div
    class="c-app flex-row align-items-center"
    id="app"
    v-bind:style="{ backgroundColor: color }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent>
                  <h1>Login</h1>
                  <div id="recaptcha-container"></div>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="User Name"
                    v-model="email"
                    maxlength="100"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>

                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                    <CCallout color="danger">
  New to or unfamiliar with flexbox? Read this CSS Tricks flexbox guide for background, terminology, guidelines, and code snippets.
</CCallout>
                    <template #description>
                      <p v-if="errors.length" class="p-error">
                        <span v-for="error in errors" :key="error">
                          <span
                            v-if="error.field == 'password'"
                            style="color: red"
                            >{{ error.error }}</span
                          >
                        </span>
                      </p>
                    </template>
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        @click="signIn()"
                        type="submit"
                        >Sign In</CButton
                      >
                    </CCol>
                    <div id="app">
                      <!-- for example router view -->
                      <router-view></router-view>
                      <!-- set progressbar -->
                      <vue-progress-bar></vue-progress-bar>
                    </div>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from "vue";
import VueProgressBar from "vue-progressbar";

Vue.use(VueProgressBar, {
  color: "rgb(0,0,139)",
  failedColor: "red",
  height: "2px",
});

export default {
  name: "Login",
  data() {
    return {
      error: "",
      errors: [],
      appVerifier: undefined,
      email: "",
      password: "",
      confirmationResult: undefined,
      counter: 0,
      color: "#eeb657",
      groupIds: [],
    };
  },
  methods: {
    getUsers() {
      fetch(
        process.env.VUE_APP_API_HOST + "/emapp/web/user/cms/login/all/groups"
      )
        .then((response) => response.json())
        .then((data) => (this.groupIds = data));
      this.$store.commit("addGroupIds", data);
    },
    signIn() {
      console.log("...signIn");
      this.start();

      console.log(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/user/cms/azure/login?username=" +
          this.email +
          "&password=" +
          this.password
      );
      fetch(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/user/cms/azure/login?username=" +
          this.email +
          "&password=" +
          this.password
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("signIn Response", data);

          if (data && data.accessToken) {
            if (
              data.authority == "ROLE_ADMIN" ||
              data.authority == "ROLE_SUPER_ADMIN"
            ) {
              console.log("access token present");
              this.$Progress.finish();
              this.$store.commit("addUser", data);
              //   this.$store.commit("addUser", data.accessToken);
              this.$router.push({ name: "Groups" });
            } else {
              console.log("failure login");
              this.fail();
              alert("You do not have permission to access this site.");
              //     // this.errors = [];
              //     //  this.errors.push({ field: "username", error: "Login Failed" });
              //     //   console.log("User couldn't sign in ", this.errors);
            }
          } else {
            console.log("failure login");
            if (data && data.message) {
              console.log("failure login with message ", data.message);
              //  alert(data.message);
              this.fail();
              this.errors = [];
              this.errors.push({ field: "password", error: "Login Failed" });
              console.log("User couldn't sign in ", this.errors);
            }
          }
        });
    },
    start() {
      this.$Progress.start();
    },
    set(num) {
      this.$Progress.set(num);
    },
    increase(num) {
      this.$Progress.increase(num);
    },
    decrease(num) {
      this.$Progress.decrease(num);
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
  mounted() {},
};
</script>
